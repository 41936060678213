import $ from 'jquery'
console.log("FFFFFF,CACACA,979797,363636,000000,2a4A5F,384b5e,437689,567587,589CBD,00B1df,97a22b,c6d643,FFd429");


var colors = [];

function getColors(){
    var inputStream = $('#color-field').val().replace(/#/g,'');
    console.log("inputStream", inputStream);
    inputStream = inputStream.replace(/[ ]{2,}/g,' ');
    inputStream = inputStream.trim();
    inputStream = inputStream.replace(/ /g,',');

    var colors_untrimed = inputStream.split(',');

    var uncleanColors = colors_untrimed.map(s => s.trim());

    var clean = true;
    colors = [];

    uncleanColors.forEach( color => {
       var colorClean = /^[0-9A-F]{6}$/i.test(color);
       console.log("Color: ", color, "clean?", colorClean);
       if(!colorClean) {
         clean = false;
       } else {
           colors.push(color);
       }
    })

    createChart();
}



function createChart(){
    var container = document.getElementById("swatch-container");
    container.innerHTML = "";
    orderColors();

    //Creat top heading row
    var headingRow = document.createElement("tr");

    //Corner swatch
    var cornerSwatch = document.createElement("td");
    cornerSwatch.classList.add('corner-swatch');
    var div = document.createElement("div")
    cornerSwatch.appendChild(div);
    headingRow.appendChild(cornerSwatch);

    colors.forEach( color => {
        var headingSwatch = document.createElement("td");
        headingSwatch.classList.add('col-heading-swatch');
        headingSwatch.appendChild(document.createTextNode('#' + color));
        headingRow.appendChild(headingSwatch);
    })

    container.appendChild(headingRow);

    for(var i = 0; i < colors.length; i++){

        var newRow = document.createElement("tr");
       
        
        //add first heading swatch of each row
        var headingSwatch = document.createElement("td");
        headingSwatch.classList.add('row-heading-swatch');
        headingSwatch.appendChild(document.createTextNode('#' + colors[i]));
        newRow.appendChild(headingSwatch);

        newRow.style.color = '#' + colors[i];
        for(var j = 0; j < colors.length; j++){
            var newSwatch = document.createElement("td");

           
            if(j===0){
                newSwatch.style.marginLeft = '60px';
            }
            newSwatch.classList.add('swatch');
            newSwatch.style.backgroundColor = '#' + colors[j];
            addContrast(colors[i],colors[j],newSwatch);
            if(colors[j] == "FFFFFF"){
                newSwatch.classList.add('grey-border');
            }
            newRow.appendChild(newSwatch);
        }
        container.appendChild(newRow);
    }
    document.getElementById('table').style.minWidth = 90 * colors.length + 'px';
    
}

function addFail(){
    if(this.checked === true){
        $('.swatch:not(.'+ this.id + 'pass)').addClass(this.id + 'failed');
    }
    else 
        $('.swatch:not(.'+ this.id + 'pass)').removeClass(this.id +'failed');

}



function addContrast(textColor, bgColor, swatch){
 
    // Formula from https://www.w3.org/TR/2008/REC-WCAG20-20081211/#contrast-ratiodef
    var l1 = brightness(textColor) + 0.05;
    var l2 = brightness(bgColor) + 0.05;
    var data = l1/l2;

    if (l2 > l1) {
        data = 1 / data;
    }

    data = data.toFixed(2);

      var newNode = document.createTextNode(data);
      swatch.appendChild(newNode);
      if(data >= 4.5){
        swatch.classList.add('aapass');
        swatch.classList.add('AAApass');
        }
      if(data >= 3){
        swatch.classList.add('AApass');
        }
       if(data >= 7){
        swatch.classList.add('aaapass');
        } else {
        swatch.classList.add('failed');

       }

}




function orderColors(){
    var newColors = [];

    for(var i = 0; i < colors.length; i++){
        newColors[i] = []
    }
    for(var i = 0; i < colors.length; i++){
        newColors[i][0] = colors[i];
        newColors[i][1] = brightness(colors[i]);    
    }
    var sorted = [];
    for( i = 0; i < newColors.length; i++){
        sorted[i] = (newColors[i][1]);
    }

    sorted.sort(function(a,b){return a-b});
    sorted.reverse();

    for( i = 0; i < colors.length; i++){
        for(var j = 0; j < colors.length; j++){
            if(newColors[j][1] === sorted[i] ){
                sorted[i] = newColors[j][0];
            }
        }
    }

    colors = sorted;
}



function brightness(color){
    var r = parseInt(color.slice(0,2), 16) / 255
    var g = parseInt(color.slice(2,4), 16) / 255
    var b = parseInt(color.slice(4,6), 16) / 255

    r = r < .03928 ? r / 12.92 : Math.pow((r + .055) / 1.055, 2.4);
    g = g < .03928 ? g / 12.92 : Math.pow((g + .055) / 1.055, 2.4);
    b = b < .03928 ? b / 12.92 : Math.pow((b + .055) / 1.055, 2.4);

    //Formula from https://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

function verifyInput(){
    var button = document.getElementById("btn-contrast-check")

    if(document.getElementById("color-field").value != ''){
        button.style.color = "#444";
        button.disabled = false;
        getColors();
    } else{
        button.style.color = "#999";
        button.disabled = true;
    }
}

var input = document.getElementById("color-field");

// Execute a function when the user releases a key on the keyboard
input.addEventListener("keyup", function(event) {
  // Number 13 is the "Enter" key on the keyboard
  if (event.keyCode === 13) {
    // Cancel the default action, if needed
    event.preventDefault();
    // Trigger the button element with a click
    document.getElementById("btn-contrast-check").click();
  }
});

document.getElementById("btn-contrast-check").disabled = true;
document.getElementById("btn-contrast-check").addEventListener("click", getColors);
document.getElementById("AA").addEventListener('click', addFail);
document.getElementById("AAA").addEventListener('click', addFail);
document.getElementById("aa").addEventListener('click', addFail);
document.getElementById("aaa").addEventListener('click', addFail);
document.getElementById("color-field").addEventListener("input", verifyInput);