import React, { useEffect } from "react"
import { graphql } from 'gatsby'
import { withPrefix } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Helmet from 'react-helmet'
import './contrast-checker.css'


const ContrastChecker = (props) => {


    useEffect(() => {
        require('../../static/javascript/contrastChecker.js')
    }, []);


    const { data } = props;
    console.log('>>>', data);
    if (!data) return null;

    let page = data.allPrismicContrastCheckerPage.nodes[0].data



    return (
        <Layout>
        <SEO title={ page.meta_title } image={ page.meta_og_image && page.meta_og_image.url } description={ page.meta_description } canonical={ typeof window !== 'undefined' && (window.location.origin + window.location.pathname)}/>
            {
                (typeof window != 'undefined') && window.location.port === 8000 &&
                <Helmet>
                    <script src={withPrefix('/javascript/contrastChecker.js')} type="text/javascript" />
                </Helmet>
            }
            <div className="title-bar banner-work" style={{ backgroundImage: `url(${page.wide_image && page.wide_image.url})`}}>
                <div className="container">
                    <div className="wrapper">
                        <h2 className="page-title">{page.contrast_checker_page_title}</h2>
                        <div className="title-bar-accent"></div>
                    </div>
                </div>
            </div>

            {/*  PAGE BODY  */}
            <section id="body-area">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="inset margin-bottom-0">
                                <h1 className="headline">{page.contrast_checker_title.text}</h1>
                                <p dangerouslySetInnerHTML={{ __html: page.contrast_checker_subheading.html }}></p>
                                <div className="text-center">
                                    <input type="text" id="color-field" placeholder="Input Hex colors separated by commas." />
                                    <button id="btn-contrast-check" ><i className="fa fa-arrow-right" aria-hidden="true"></i></button>
                                </div>
                            </div> {/*  /box  */}
                        </div>
                    </div>
                    <div className="row text-center">
                        <div className="col-md-4 d-flex justify-content-between">
                            <div className="row">
                                <div className="col-xs-6 col-sm-3">
                                    <input type="checkbox" id="aa" />
                                    <label htmlFor="aa" className="label-toggle">aa</label>
                                    <div className="circ-elem"></div>
                                </div>
                                <div className="col-xs-6 col-sm-3">
                                    <input type="checkbox" id="AA" />
                                    <label htmlFor="AA" className="label-toggle">AA</label>
                                    <div className="circ-elem"></div>
                                </div>
                                <div className="col-xs-6 col-sm-3">
                                    <input type="checkbox" id="aaa" />
                                    <label htmlFor="aaa" className="label-toggle">aaa</label>
                                    <div className="circ-elem"></div>
                                </div>
                                <div className="col-xs-6 col-sm-3">
                                    <input type="checkbox" id="AAA" />
                                    <label htmlFor="AAA" className="label-toggle">AAA</label>
                                    <div className="circ-elem"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xs-12" style={{ paddingRight: 0 }}>
                            <div className="table-responsive" >
                                <table className="table" id='table' >
                                    <tbody id="swatch-container">

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="row inset">
                        <div className="col-md-7" dangerouslySetInnerHTML={{ __html: page.contrast_checker_body_text.html }}></div>
                    </div> {/*  /row  */}

                </div> {/*  /container  */}
            </section>

        </Layout>
    );
}

export default ContrastChecker;

export const ContrastCheckerQuery = graphql`
query MyQuery {
    allPrismicContrastCheckerPage {
      nodes {
        data {
          contrast_checker_body_text {
            html
          }
          contrast_checker_page_title
          contrast_checker_subheading {
            html
          }
          contrast_checker_title {
            text
          }
          meta_title
          meta_description
          meta_og_image {
            url
          }
          wide_image {
              url
          }
        }
      }
    }
  }
`